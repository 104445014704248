export enum NavbarTypes {
  SET_TITLE = `@navbar/SET_TITLE`,
  SET_DIRECTION = `@navbar/SET_DIRECTION`,
  SET_BACK_NAVIGATION_HANDLER = `@navbar/SET_BACK_NAVIGATION_HANDLER`,
}

export interface NavbarState {
  readonly title: string
  readonly direction: string
  readonly backNavigationHandler: () => void
}
