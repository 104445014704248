import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { NavbarActions } from 'src/store/ducks/navbar/actions'

interface BasePage {
  navbarTitle: PageTitles
  backNavigationHandler?: () => void
}

export const useBasePage = (data: BasePage) => {
  const dispatch = useDispatch()

  const { navbarTitle, backNavigationHandler } = data

  useEffect(() => {
    dispatch(NavbarActions.setTitle(navbarTitle))

    if (backNavigationHandler) {
      dispatch(NavbarActions.setBackNavigationHandler(backNavigationHandler))
      BaseBridge.addBackListener(backNavigationHandler)
    }
  }, [dispatch, navbarTitle])
}
