import { Reducer } from 'redux'

import { DocumentState, DocumentTypes, RedirectToDocument } from './types'

const INITIAL_STATE: DocumentState = {
  redirectToDocument: {} as RedirectToDocument,
}

const reducer: Reducer<DocumentState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DocumentTypes.SET_REDIRECT_TO_DOCUMENT:
      return { ...state, redirectToDocument: action.payload }

    default:
      return state
  }
}

export default reducer
