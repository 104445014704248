import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'

export enum DocumentTypes {
  GET_REDIRECT_TO_DOCUMENT = `@subscription/GET_REDIRECT_TO_DOCUMENT`,
  SET_REDIRECT_TO_DOCUMENT = `@subscription/SET_REDIRECT_TO_DOCUMENT`,
}

export interface RedirectToDocument extends SagaWithHistory {
  type: string
}

export interface DocumentState {
  readonly redirectToDocument?: RedirectToDocument
}
