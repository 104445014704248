import { Document } from 'src/components'
import { CoverageAvailable } from 'src/pages/phoneInsurance'

import { TypesRoutes as Routes } from './types'

export const mixedRoutes = [
  { route: Routes.COVERAGE_AVAILABLE, component: CoverageAvailable },
  { route: Routes.DOCUMENTS, component: Document },
]

export * from './types'
