import React, { useLayoutEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { DefaultTheme, ThemeProvider } from 'styled-components'

import { GlobalUIInterStyle, InterTheme, InterUIFonts } from '@interco/inter-ui-react-lib'
import { Worker } from '@react-pdf-viewer/core'

import { Navbar } from './components/Navbar/Navbar'
import BaseBridge from './config/bridge/BaseBridge'
import { ThemesEnum } from './enums/ThemesEnum'
import Routes from './routes'
import { ErrorActions } from './store/ducks/error/actions'

const App: React.FC = () => {
  const dispatch = useDispatch()

  InterUIFonts()

  const [theme, setTheme] = useState<DefaultTheme>(InterTheme(ThemesEnum.LIGHT))

  const workerUrl = 'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'

  useLayoutEffect(() => {
    chooseTheme()
  }, [])

  const chooseTheme = () => {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, esta funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
      disabledButton: false,
    }

    BaseBridge.getAppInfo()
      .then((response) => {
        const isDarkMode = (response as unknown as { isDarkMode: boolean })?.isDarkMode

        isDarkMode ? setTheme(InterTheme(ThemesEnum.DARK)) : setTheme(InterTheme(ThemesEnum.LIGHT))
      })
      .catch(() => dispatch(ErrorActions.show(errorDetails)))
  }

  return (
    theme && (
      <ThemeProvider theme={theme}>
        <GlobalUIInterStyle />
        <BrowserRouter>
          <Navbar />
          <Routes />
        </BrowserRouter>
        <Worker workerUrl={workerUrl} />
      </ThemeProvider>
    )
  )
}

export default App
