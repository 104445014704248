import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import manualPdf from 'src/assets/manual.pdf'
import condicoesGerais from 'src/assets/condicoesGerais.pdf'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { DocumentActions } from 'src/store/ducks/document/action'

import { InterUIContainer, InterUILoading } from '@interco/inter-ui-react-lib'
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'

import { Button } from '..'

import { LoadContainer, PdfContainer } from './Document.styles'

import '@react-pdf-viewer/core/lib/styles/index.css'

export const Document: React.FC = () => {
  const history = useHistory()

  const getRedirectDocument = useSelector(DocumentActions.getRedirectToDocument)

  const [enabledButton, setEnabledButton] = useState(false)

  useBasePage({
    navbarTitle: getRedirectDocument?.type === 'MANUAL' ? PageTitles.MANUAL : PageTitles.TERMS,
    backNavigationHandler: history.goBack,
  })

  const stickyFooter = (
    <>
      {enabledButton && (
        <InterUIContainer margin="24px">
          <Button
            variant="primary"
            margin="0 0 16px"
            onClick={() => {
              history.goBack()
            }}
          >
            Entendi
          </Button>
        </InterUIContainer>
      )}
    </>
  )

  const loading = (
    <LoadContainer>
      <InterUILoading size="xl" />
    </LoadContainer>
  )

  if (getRedirectDocument?.type === 'MANUAL') {
    return (
      <InterUIContainer stickyFooter={stickyFooter} margin="0px">
        <PdfContainer>
          <Viewer
            fileUrl={manualPdf}
            renderLoader={() => loading}
            onDocumentLoad={() => setEnabledButton(true)}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </PdfContainer>
      </InterUIContainer>
    )
  }

  return (
    <InterUIContainer stickyFooter={stickyFooter} margin="0px">
      <PdfContainer>
        <Viewer
          fileUrl={condicoesGerais}
          renderLoader={() => loading}
          onDocumentLoad={() => setEnabledButton(true)}
          defaultScale={SpecialZoomLevel.PageFit}
        />
      </PdfContainer>
    </InterUIContainer>
  )
}
