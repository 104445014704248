import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { DocumentTypes, RedirectToDocument } from './types'

const getRedirectToDocument = (state: RootState) => state.document.redirectToDocument

const setRedirectToDocument = (data: RedirectToDocument) =>
  action(DocumentTypes.SET_REDIRECT_TO_DOCUMENT, data)

export const DocumentActions = {
  getRedirectToDocument,
  setRedirectToDocument,
}
