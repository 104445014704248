import { PageTitles } from 'src/enums/pageTitles'
import { PageDirections } from 'src/routes/pageDirections'
import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { NavbarTypes } from './types'

const getTitle = (state: RootState) => state.navbar.title

const setTitle = (data: PageTitles) => action(NavbarTypes.SET_TITLE, data)

const setDirection = (direction: PageDirections) => action(NavbarTypes.SET_DIRECTION, direction)

const getDirection = (state: RootState) => state.navbar.direction

const setBackNavigationHandler = (cb: () => void) =>
  action(NavbarTypes.SET_BACK_NAVIGATION_HANDLER, cb)

const getBackNavigationHandler = (state: RootState) => state.navbar.backNavigationHandler

export const NavbarActions = {
  getTitle,
  setTitle,
  setDirection,
  getDirection,
  setBackNavigationHandler,
  getBackNavigationHandler,
}
