import React from 'react'

import { useSelector } from 'react-redux'
import { LoadingActions } from 'src/store/ducks/loading/actions'

import { IInterUIButtonProps, InterUIButton } from '@interco/inter-ui-react-lib'

export const Button: React.FC<IInterUIButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> =
  ({ children, ...props }) => {
    const loading = useSelector(LoadingActions.get)

    return (
      <InterUIButton {...props} loading={loading}>
        {children}
      </InterUIButton>
    )
  }
