import { combineReducers } from 'redux'

import document from './document'
import loading from './loading'
import navbar from './navbar'
import error from './error'

const rootReducer = combineReducers({
  navbar,
  loading,
  document,
  error,
})

export default rootReducer
