import { History } from 'history'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { put } from 'typed-redux-saga'

import { LoadingActions } from '../loading/actions'

export interface SagaWithHistory {
  history?: History
  pathname?: string
  goBack?: boolean
}

/**
 * Realiza o redirecionamento utilizando o useHistory recebido por parâmetro.
 * @param payload Payload recebido através da action.
 */
export function redirectThroughSaga(payload: SagaWithHistory): void {
  if (payload && payload.history && (payload.pathname || payload.goBack)) {
    const { history } = payload

    if (payload.goBack) {
      history.goBack()
    } else if (payload?.pathname) {
      history.push(payload.pathname)
    }
  }
}

/**
 * Tratativa de erro a ser realizada no Saga.
 */
export function* errorHandlingSaga(error: Error) {
  NewRelicUtils.noticeError(error)
  yield* put(LoadingActions.hide())
}
