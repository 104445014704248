import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { DocumentActions } from 'src/store/ducks/document/action'
import { useTheme } from 'styled-components'

import Pdf from '@interco/inter-frontend-svgs/lib/orangeds/MD/pdf-file'
import Arrow from '@interco/inter-frontend-svgs/lib/orangeds/MD/chevron-right'
import Check from '@interco/inter-frontend-svgs/lib/orangeds/MD/check'
import Book from '@interco/inter-frontend-svgs/lib/orangeds/MD/book-open'
import {
  InterUIContainer,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { Coverage } from '../../../components'
import BaseBridge from '../../../config/bridge/BaseBridge'
import { PageTitles } from '../../../enums/pageTitles'
import { useBasePage } from '../../../hooks/useBasePage'
import { benefits } from '../../../mocks/CoverageAvailable'
import { H2, H3, PMedium, PSmall } from '../../../styles/commons'

export const CoverageAvailable: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const iconCheck = <Check color={theme.colors.neutral.grayscale.A500} />
  const iconRight = <Arrow color={theme.colors.primary.A500} />
  const iconBook = <Book color={theme.colors.neutral.grayscale.A500} />
  const iconPdf = <Pdf color={theme.colors.neutral.grayscale.A500} />

  useBasePage({
    navbarTitle: PageTitles.COVERAGE,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  const handleDocument = (document: string) => {
    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENTS,
        type: document,
      }),
    )
  }

  return (
    <InterUIContainer margin="24px 0 32px">
      <InterUIContainer margin="0 24px">
        <H2>Benefícios</H2>
        {benefits.map((item, index) => {
          const key = `additional-${index}`

          return (
            <InterUIListDescription
              key={key}
              margin="16px 0 0"
              iconLeft={iconCheck}
              AlignOnlyLeftIcon="center"
              role="group"
            >
              <PMedium scale={400} marginBottom="0">
                {item}
              </PMedium>
            </InterUIListDescription>
          )
        })}
      </InterUIContainer>

      <InterUISeparator height="8px" margin="32px 0" />

      <Coverage />

      <InterUISeparator height="8px" margin="0 0 32px" />

      <InterUIContainer margin="0 24px">
        <H3 marginBottom="8px">Cancelamento</H3>
        <PMedium scale={400} marginBottom="0">
          Cobrança mensal, cancele quando quiser.
        </PMedium>
      </InterUIContainer>

      <InterUISeparator height="8px" margin="32px 0" />

      <InterUIContainer margin="0 24px">
        <H3 marginBottom="8px">Sorteio</H3>
        <PMedium scale={400} marginBottom="0">
          A partir do segundo mês, participe de sorteios e concorra a prêmios mensais de R$
          5.000,00.
        </PMedium>
      </InterUIContainer>

      <InterUISeparator height="8px" margin="32px 0" />

      <InterUIContainer margin="0 24px">
        <H3 marginBottom="8px">Termos e condições</H3>
        <PSmall scale={400} marginBottom="32px">
          Ao contratar o Seguro Celular, declaro e confirmo que li e estou de acordo com os Termos e
          Condições que constam no <strong>Manual do Produto</strong> e nas{' '}
          <strong>Condições Gerais</strong> e que, em caso de acionamento, devo ter a nota fiscal em
          mãos.
        </PSmall>
        <InterUIListDescription
          margin="16px 0 0"
          iconLeft={iconBook}
          iconRight={iconRight}
          borderBottom
          alignAllItems="center"
          role="group"
          onClick={() => handleDocument('MANUAL')}
        >
          <PSmall marginBottom="0" bold>
            Manual do produto
          </PSmall>
        </InterUIListDescription>
        <InterUIListDescription
          margin="16px 0 32px"
          iconLeft={iconPdf}
          iconRight={iconRight}
          borderBottom
          alignAllItems="center"
          role="group"
          onClick={() => handleDocument('TERMS')}
        >
          <PSmall marginBottom="0" bold>
            Condições Gerais
          </PSmall>
        </InterUIListDescription>
        <PSmall scale={400} marginBottom="16px">
          <strong>Usuário final:</strong> esse seguro só terá validade se a pessoa que utiliza esse
          celular for você, sua mãe, pai, cônjuge ou filhos acima de 16 anos de idade.
        </PSmall>
        <PSmall scale={400} marginBottom="0">
          <strong>Validade:</strong> sem carência e o cancelamento é gratuito.
        </PSmall>
      </InterUIContainer>
    </InterUIContainer>
  )
}
