import React from 'react'

import { InterUIAccordion, InterUIContainer } from '@interco/inter-ui-react-lib'

import { additionalCoverage, guaranteedCoverage } from '../../mocks/CoverageAvailable'
import { H3, PMedium, PSmall } from '../../styles/commons'
import { CoverageContent } from './ Coverage.styles'

export const Coverage: React.FC = () => (
  <InterUIContainer margin="0 24px">
    <H3 marginBottom="8px">Cobertura garantida</H3>
    <CoverageContent>
      {guaranteedCoverage.map((item, index) => {
        const key = `guaranteed-${index}`

        return (
          <InterUIAccordion
            key={key}
            id={key}
            data-testid={key}
            header={<PMedium marginBottom="0">{item.question}</PMedium>}
            border="bottom"
          >
            <PSmall scale={400} marginBottom="16px">
              {item.firstAnswer}
            </PSmall>
            <PSmall scale={400} marginBottom="0">
              {item.secondAnswer}
            </PSmall>
          </InterUIAccordion>
        )
      })}
    </CoverageContent>

    <H3 marginBottom="8px">Coberturas adicionais</H3>
    <CoverageContent>
      {additionalCoverage.map((item, index) => {
        const key = `additional-${index}`

        return (
          <InterUIAccordion
            key={key}
            id={key}
            data-testid={key}
            header={<PMedium marginBottom="0">{item.question}</PMedium>}
            border={additionalCoverage.length !== index + 1 ? 'bottom' : 'none'}
          >
            <PSmall scale={400} marginBottom="16px">
              {item.firstAnswer}
            </PSmall>
            <PSmall scale={400} marginBottom="0">
              {item.secondAnswer}
            </PSmall>
          </InterUIAccordion>
        )
      })}
    </CoverageContent>
  </InterUIContainer>
)
